<template>
    <teleport to="head">
        <title>Renewals - Rovner Apps</title>
    </teleport>
    <Main>
        <template v-if="modalComponent">
            <component :is="modalComponent" />
        </template>

        <div class="flex-none">
           <SearchBar />
        </div>
        <div class="text-center table-container flex-1">
            <Table :rows="rows" />
        </div>
        <div class="pagination flex-none">
            <PerPage :pagination="pagination" @fetchData="fetchData" />
             <NoOfRows  :pagination="pagination" singular="Renewal" plural="Renewals"/>
            <Pagination :pagination="pagination" @fetchData="fetchData" />
        </div>
    </Main>
</template>

<script>
import Main from '../../components/Main';
import Table from './table/Index.vue';
import SearchBar from './SearchBar.vue';
import Pagination from '../../components/Pagination';
import PerPage from '../../components/PerPage';
import NoOfRows from '../../components/NoOfRows';

import CustomerEdit from '../customers/modal/Edit';
import Terminate from './modal/Terminate';
import Renew from './modal/Renew';

export default {
    components: { Main, Table, Pagination, PerPage, SearchBar, Renew, Terminate, CustomerEdit, NoOfRows },
    data() {
        return {};
    },

    computed: {
        rows() {
            return this.$store.getters['renewals/getTableRows'];
        },
        pagination() {
            return this.$store.getters['renewals/getTablePagination'];
        },
        modalComponent() {
            return this.$store.state.renewals.modal.component;
        },
    },
    methods: {
        async fetchData(obj) {
           if('perpage' in obj) {
                localStorage.setItem('renewals_per_page', obj.perpage);
                delete obj.perpage
            }
            if (obj) {
                this.$store.commit('renewals/setSearch', obj);
            }
            await this.$store.dispatch('renewals/fetchTableRows', obj);
        },
    },
};
</script>
<style scoped>

</style>
<style>
body {
    min-width: 700px;
}
</style>
