<template>
    <Modal type="Terminate Policy" @submitData="submitData" :isTerminate="true"/>
</template>

<script>
import Modal from './Index';
import axios from 'axios';

export default {
    components: { Modal },
    methods: {
        async submitData() {
            this.loader();
            await this.$store.dispatch('renewals/terminate');
            this.loader(false);
            this.$store.commit('renewals/hideModal');
            await this.$store.dispatch('renewals/fetchTableRows');
        },
    },
};
</script>
