<template>
    <section class="modal" v-esc="hideModal">
        <form
            class="container"
            :class="isTerminate && 'delete'"
            @submit.prevent="submitData"
            @keydown="
                (e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                    }
                }
            "
            style="width: 1219px"
        >
            <div class="header flex items-center justify-center text-white">
                <h4 class="font-bold">{{ type }}</h4>
                <a @click.prevent="hideModal" class="x-button">
                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9.77932 12.2984C7.72583 14.3649 5.7198 16.3838 3.72239 18.3985C2.70859 17.3804 1.71205 16.3795 0.715501 15.3743C2.70428 13.3769 4.71894 11.3623 6.73792 9.33467C4.70168 7.29844 2.68702 5.27946 0.685303 3.27343C1.6991 2.25531 2.69565 1.25445 3.71808 0.227705C5.7198 2.24668 7.72583 4.26997 9.75775 6.31483C11.8069 4.25272 13.813 2.22943 15.8147 0.210449C16.8457 1.25014 17.8423 2.251 18.8388 3.25617C16.8544 5.24926 14.8397 7.26824 12.8423 9.27427C14.8699 11.3062 16.8759 13.3209 18.869 15.3226C17.8423 16.3493 16.8371 17.3502 15.8147 18.3769C13.8345 16.3881 11.8285 14.3649 9.77932 12.2984Z"
                            fill="white"
                        />
                    </svg>
                </a>
            </div>

            <div class="content">
                <div class="grid grid-cols-4 gap-x-5 gap-y-8" :class="[isTerminate ? 'grid-cols-4' : 'grid-cols-5']">
                    <div class="input-container">
                        <label> Name </label>
                        <input :value="details.fullName" disabled />
                    </div>

                    <div class="input-container">
                        <label> Company </label>
                        <input :value="details.company" disabled />
                    </div>

                    <div class="input-container">
                        <label> Email </label>
                        <input :value="details.address" disabled />
                    </div>
                </div>

                <div class="hr mt-8"></div>

                <div class="grid grid-cols-4 gap-x-5 gap-y-8 pt-6" :class="[isTerminate ? 'grid-cols-4' : 'grid-cols-5']">
                    <Date v-model="details.effective" title="Effective" :disabled="true" />
                    <Date v-model="details.expires" title="Expires" :disabled="true" />
                    <div class="input-container">
                        <label> Carrier </label>
                        <input v-model.trim="details.carrier" required disabled />
                    </div>
                    <Select v-model="details.type" :options="types" title="Type" :disabled="true" />
                    <template v-if="!isTerminate">
                        <Money v-model.trim="details.premium" title="Old Premium" :disabled="true" />
                        <Percent style="justify-content: flex-start" v-model="details.commissionRate" title="Commission Rate" :disabled="true" />
                    </template>

                    <div class="col-span-2">
                        <div class="input-container">
                            <label> Notes</label>
                            <textarea
                                style="height: 103.64px"
                                rows="3"
                                v-model.trim="details.notes"
                                :readonly="true"
                                v-on:keyup.enter="
                                    (e) => {
                                        e.target.value += '\n';
                                    }
                                "
                            ></textarea>
                        </div>
                    </div>
                </div>
                <div class="hr1 mt-8"><div>New Policy</div></div>

                <template v-if="isTerminate">
                    <div class="grid gap-x-5 gap-y-8 pt-10" :class="[isTerminate ? 'grid-cols-4' : 'grid-cols-5']">
                        <Money v-model.trim="details.premium" title="Premium" :disabled="true" />
                    </div>
                </template>

                <template v-else>
                    <div class="grid grid-cols-5 gap-x-5 gap-y-8 pt-10">
                        <div class="grid grid-cols-3 col-span-3 gap-y-8 gap-x-5">
                            <Date v-model="renew.effective" title="Effective" :required="true" />
                            <Date v-model="renew.expires" title="Expires" :required="true" />
                            <Percent v-model="renew.commissionRate" title="Commission Rate" />
                            <Money v-model.trim="renew.premium" title="Premium" :required="true" />
                            <Percent v-model.trim="adjusted" title="Adjusted" :disabled="true" />
                        </div>
                        <div class="grid col-span-2">
                            <div class="input-container h-full">
                                <label> Notes</label>
                                <textarea
                                    class="flex-grow"
                                    v-model.trim="renew.notes"
                                    v-on:keyup.enter="
                                        (e) => {
                                            e.target.value += '\n';
                                        }
                                    "
                                ></textarea>
                            </div>
                        </div>
                    </div>
                </template>

                <div class="buttons flex justify-center flex-row pt-12">
                    <button :class="isTerminate && 'terminate'" class="save w-full" type="submit"><span>Save</span></button>
                    <button class="cancel w-full" type="button" @click="hideModal">Cancel</button>
                </div>
            </div>
        </form>
    </section>
</template>

<script>
import Date from '../../../components/Date';
import Select from '../../../components/Select';
import Money from '../../../components/Money';
import Percent from '../../../components/Percent';
import CheckBox from '../../../components/CheckBox';
import CheckBoxOptional from '../../../components/CheckBoxOptional';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);
export default {
    props: {
        type: String,
        defaultdata: Object,
        isTerminate: {
            type: Boolean,
            default: false,
        },
    },

    components: {Date, CheckBox, Money, Percent, Select, CheckBoxOptional},

    computed: {
        details() {
            return this.$store.state.renewals.modal.details;
        },

        renew() {
            return this.details.renew;
        },

        customer() {
            return this.details.customer;
        },

        customersList() {
            return this.$store.state.customers.customerList;
        },
        types() {
            return this.$store.getters['policies/getTypes']();
        },

        premium: {
            get() {
                return this.renew.premium;
            },
            set(v) {
                this.$store.commit('renewals/setRenewDetails', {premium: v});
            },
        },

        effective: {
            get() {
                return this.renew.effective;
            },
            set(v) {
                this.$store.commit('renewals/setRenewDetails', {effective: v});

                const expires = this.addOneYear(v);
                if (!this.expires && expires) {
                    this.$store.commit('renewals/setRenewDetails', {expires});
                }
            },
        },

        expires: {
            get() {
                return this.renew.expires;
            },
            set(v) {
                this.$store.commit('renewals/setRenewDetails', {expires: v});
            },
        },

        adjusted() {
            let percent;
            let oldPremium = this.details.premium;
            let premium = this.renew.premium;

            if (!oldPremium || !premium) {
                return;
            }

            oldPremium = oldPremium.toString().replace(/[^0-9.]/g, '');
            premium = premium.toString().replace(/[^0-9.]/g, '');

            if (!oldPremium || !premium) {
                return;
            }

            const searchNull = [oldPremium, premium, this.details.renew.expires, this.details.renew.effective].some((item) => {
                if (!item || parseFloat(item) === 0) {
                    return 1;
                }
            });

            if (searchNull) {
                return;
            }

            let newDaysDiff = this.daysDiffAllowNegative(this.details.renew.expires, this.details.renew.effective);
            let oldDaysDiff = this.daysDiffAllowNegative(this.details.expires, this.details.effective);

            if (parseFloat(newDaysDiff) === 0 || parseFloat(oldDaysDiff) === 0) {
                return '';
            }

            const newDaily = premium / newDaysDiff;
            const oldDaily = oldPremium / oldDaysDiff;

            const added = newDaily - oldDaily;

            percent = (added * 100) / oldDaily;
            percent = Math.round((percent + Number.EPSILON) * 100) / 100;
            return percent;
        },
    },

    mounted() {
        if (this.defaultdata) {
            this.details = this.defaultdata;
        }
    },

    methods: {
        hideModal() {
            this.$store.commit('renewals/hideModal');
        },

        submitData(e) {
            e.preventDefault();

            if (!this.isTerminate) {
                if (!/\d\d?\/\d\d?\/\d{4}$/g.test(this.effective)) {
                    return;
                }

                if (!/\d\d?\/\d\d?\/\d{4}$/g.test(this.expires)) {
                    return;
                }

                if (!this.premium) {
                    return;
                }
            }

            this.$emit('submitData');
        },
    },
};
</script>

<style scoped lang="scss">
.hr {
    width: 100%;
    border-top: 1px dashed #e3e3e3;
}
.hr1 {
    width: 100%;
    border-top: 1px solid rgba(4, 127, 255, 1);
    position: relative;
    display: flex;
    justify-content: center;
    div {
        font-weight: 700;
        font-size: 20px;
        color: rgba(4, 127, 255, 1);
        width: 122.72px;
        background: white !important;
        position: absolute;
        right: 0;
        left: 0;
        top: -15px;
        bottom: 0;
        margin-right: auto;
        margin-left: auto;
        height: 30px;
        text-align: center;
        z-index: 6;
    }
}

.delete {
    width: 958px !important;
    .hr1 {
        border-top: 1px dashed rgba(227, 227, 227, 1);
        div {
            display: none;
        }
    }
    button.terminate {
        &:after {
            content: 'Confirm' !important;
        }
    }
}
</style>
