<template>
    <section class="flex search-bar">
        <div class="input-container" style="width: 170.36px">
            <label> Name </label>
            <input v-model.trim="search.fullName" @keyup.enter="triggerSearch" />
        </div>
        <div class="input-container" style="width: 170.36px">
            <label> Company </label>
            <input v-model.trim="search.company" @keyup.enter="triggerSearch" />
        </div>

        <div style="width: 130px">
            <Date v-model="search.expiresFrom" ref="effectiveFrom" title="Expires From" />
        </div>

        <div style="width: 130px">
            <Date v-model="search.expiresTo" ref="expiresTo" title="Expires To" />
        </div>

        <div class="input-container" style="width: 159.19px">
            <button class="reset w-full" @click="resetSearch">Reset</button>
        </div>
        <div class="input-container" style="width: 159.19px">
            <button class="search w-full" @click="triggerSearch">Search</button>
        </div>
    </section>
</template>

<script>
import Date from '@/components/Date';
import dayjs from 'dayjs';

export default {
    components: {Date},
    data() {
        return {};
    },

    computed: {
        search() {
            return this.$store.getters['renewals/getSearch'];
        },
    },

    mounted() {
        window.history.replaceState({}, document.title, window.location.pathname);
        this.setExpires()
        this.triggerSearch();
    },

    methods: {
        async triggerSearch() {
            const effectiveFromError = this.$refs.effectiveFrom.error;
            const expiresToError = this.$refs.expiresTo.error;

            if (effectiveFromError || expiresToError) {
                return;
            }

            this.setQueryParams(this.search);
            await this.$store.dispatch('renewals/fetchTableRows', {});
        },

        setExpires() {
            let params = {};

            if (!this.search.expiresFrom) {
                params.expiresFrom = dayjs().subtract(20, 'day').format('M/D/YYYY');
            }

            if (!this.search.expiresTo) {
                params.expiresTo = dayjs().add(90, 'day').format('M/D/YYYY');
            }

            this.$store.commit('renewals/setSearch', params);
        },

        resetSearch() {
            this.$store.commit('renewals/resetSearch');
            this.setExpires()
            //window.history.pushState({}, document.title, window.location.pathname);
            window.history.replaceState({}, document.title, window.location.pathname);
        },
    },
};
</script>
