<template>
    <Modal type="Renew Policy" @submitData="submitData" />
</template>

<script>
import Modal from './Index';
import axios from 'axios';

export default {
    components: { Modal },
    methods: {
        async submitData() {
            this.loader();
            const id = this.$store.state.policies.modal.details.id;
            await this.$store.dispatch('renewals/renew', this.$store.state.renewals.modal.details);
            this.loader(false);
            this.$store.commit('renewals/hideModal');
            await this.$store.dispatch('renewals/fetchTableRows');
        },
    },
};
</script>
