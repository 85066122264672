<template>
    <section  class="table-section">
        <table>
            <thead>
                <tr>
                    <th>Renew</th>
                    <th>Name</th>
                    <th>Company</th>
                    <th>Effective</th>
                    <th>Expires</th>
                    <th>Days</th>
                    <th>Carrier</th>
                    <th>Type</th>
                    <th>Premium</th>
                </tr>
            </thead>
            <tbody v-if="rows.length > 0">
                <tr v-for="(row, i) in rows" :key="i">
                    <td style="padding-left: 0; width: 80px">
                        <div class="override-table-icons">
                            <a href="#" class="renew" @click.prevent="renew(row, true)">
                                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M11.6587 0.228271C5.58369 0.228271 0.658691 5.15327 0.658691 11.2283C0.658691 17.3033 5.58369 22.2283 11.6587 22.2283C17.7337 22.2283 22.6587 17.3033 22.6587 11.2283C22.6587 5.15327 17.7337 0.228271 11.6587 0.228271ZM16.4267 9.36827C16.5145 9.26791 16.5813 9.15101 16.6233 9.02444C16.6652 8.89787 16.6814 8.76419 16.671 8.63126C16.6605 8.49833 16.6235 8.36884 16.5623 8.2504C16.501 8.13196 16.4167 8.02698 16.3142 7.94161C16.2118 7.85625 16.0933 7.79223 15.9658 7.75333C15.8382 7.71443 15.7042 7.70143 15.5716 7.7151C15.4389 7.72876 15.3104 7.76882 15.1934 7.8329C15.0765 7.89699 14.9736 7.98382 14.8907 8.08827L10.5907 13.2473L8.36569 11.0213C8.17709 10.8391 7.92449 10.7383 7.66229 10.7406C7.40009 10.7429 7.14928 10.848 6.96387 11.0335C6.77846 11.2189 6.6733 11.4697 6.67102 11.7319C6.66874 11.9941 6.76953 12.2467 6.95169 12.4353L9.95169 15.4353C10.0499 15.5335 10.1676 15.6101 10.2971 15.6603C10.4266 15.7105 10.5652 15.7332 10.704 15.7269C10.8427 15.7206 10.9787 15.6855 11.1031 15.6237C11.2275 15.562 11.3377 15.475 11.4267 15.3683L16.4267 9.36827Z"
                                        fill="#00C29D"
                                    />
                                </svg>
                            </a>

                            <a href="#" class="cancel" @click.prevent="renew(row, false)">
                                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M11.5 0.228271C5.425 0.228271 0.5 5.15327 0.5 11.2283C0.5 17.3033 5.425 22.2283 11.5 22.2283C17.575 22.2283 22.5 17.3033 22.5 11.2283C22.5 5.15327 17.575 0.228271 11.5 0.228271ZM15.207 8.93527C15.3892 8.74667 15.49 8.49407 15.4877 8.23187C15.4854 7.96967 15.3802 7.71886 15.1948 7.53345C15.0094 7.34804 14.7586 7.24288 14.4964 7.2406C14.2342 7.23832 13.9816 7.33911 13.793 7.52127L11.5 9.81427L9.207 7.52127C9.11475 7.42576 9.00441 7.34958 8.8824 7.29717C8.7604 7.24476 8.62918 7.21717 8.4964 7.21602C8.36362 7.21487 8.23194 7.24017 8.10905 7.29045C7.98615 7.34073 7.8745 7.41498 7.78061 7.50888C7.68671 7.60277 7.61246 7.71442 7.56218 7.83732C7.5119 7.96021 7.4866 8.09189 7.48775 8.22467C7.4889 8.35745 7.51649 8.48867 7.5689 8.61068C7.62131 8.73268 7.69749 8.84302 7.793 8.93527L10.086 11.2283L7.793 13.5213C7.69749 13.6135 7.62131 13.7239 7.5689 13.8459C7.51649 13.9679 7.4889 14.0991 7.48775 14.2319C7.4866 14.3646 7.5119 14.4963 7.56218 14.6192C7.61246 14.7421 7.68671 14.8538 7.78061 14.9477C7.8745 15.0416 7.98615 15.1158 8.10905 15.1661C8.23194 15.2164 8.36362 15.2417 8.4964 15.2405C8.62918 15.2394 8.7604 15.2118 8.8824 15.1594C9.00441 15.107 9.11475 15.0308 9.207 14.9353L11.5 12.6423L13.793 14.9353C13.9816 15.1174 14.2342 15.2182 14.4964 15.2159C14.7586 15.2137 15.0094 15.1085 15.1948 14.9231C15.3802 14.7377 15.4854 14.4869 15.4877 14.2247C15.49 13.9625 15.3892 13.7099 15.207 13.5213L12.914 11.2283L15.207 8.93527Z"
                                        fill="#F3702D"
                                    />
                                </svg>
                            </a>
                        </div>
                    </td>
                    <td @click="showEditCustomer(row)" class="cursor-pointer">
                        <div class="highlight whitespace-nowrap">{{ row.fullName }}</div>
                    </td>
                    <td>
                        <div class="whitespace-nowrap">{{ row.company }}</div>
                    </td>
                    <td>
                        <div  class="whitespace-nowrap">{{ row.effective }}</div>
                    </td>
                    <td>
                        <div  class="whitespace-nowrap">{{ row.expires }}</div>
                    </td>
                    <td>
                        <div class="days-away" :style="'background:' + calculateBgColor(row.daysAway)">{{ row.daysAway }}</div>
                    </td>
                    <td>
                        <div class="whitespace-nowrap">{{ row.carrier }}</div>
                    </td>
                    <td>
                        <div  class="whitespace-nowrap">{{ row.type }}</div>
                    </td>
                    <td>
                        <div  class="whitespace-nowrap">{{ toDollar(row.premium) }}</div>
                    </td>
                </tr>
            </tbody>
            <template v-else>
                <tr>
                    <td colspan="19">
                        <div class="text-black text-center py-4" >No data found</div>
                    </td>
                </tr>
            </template>
        </table>
    </section>
</template>

<script>
export default {
    props: ['rows'],
    name: 'Table',
    methods: {
        async renew(row, renew) {
            // await this.$store.dispatch('renewals/renew', { renew, rowId });
            // await this.$store.dispatch('renewals/fetchTableRows');

            if(renew) {
               this.$store.commit('renewals/showRenew', row);
            } else {
               this.$store.commit('renewals/showTerminate', row);
            }
        },

        calculateBgColor(days) {
            if (0 > days) {
                return 'rgba(255, 92, 92, 1)';
            }
            const monthDays = new Date(2025, new Date().getMonth() + 1, 0).getDate();
            if (monthDays > days) {
                return 'rgba(255, 197, 47, 1)';
            }
            return 'rgba(193, 193, 193, 1)';
        },

        async showEditCustomer({customerId}) {
            const data = await this.$store.dispatch('customers/readTableRow', {customerId});
            this.$store.commit('customers/setDetails', data);
            this.$store.commit('renewals/showCustomerEdit');
        },
    },
    
};
</script>
<style scoped lang="scss">
section {
    .days-away {
        height: 26px;
        font-size: 14px;
        color: white;
        font-weight: 700;
        line-height: 26px;
        border-radius: 26px;
        padding-right: 12px;
        padding-left: 12px;
        display: inline-block;
        vertical-align: middle;
    }

    .override-table-icons {
        padding-left: 7.95px;
        display: flex;
        align-items: center;

        a {
            padding: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid transparent;
            border-radius: 50%;
        }
        .renew {
            &:hover {
                border: 1px solid #00c29d;
            }
            &:active {
                border: 1px solid #00b18f;
                svg path {
                    fill: #00b18f;
                }
            }
        }
        .cancel {
            &:hover {
                border: 1px solid #f3702d;
            }
            &:active {
                border: 1px solid #d55d1f;
                svg path {
                    fill: #d55d1f;
                }
            }
        }
    }
}
</style>
